.menu-group {
  display: flex;
  justify-content: center;
  background-color: #ededed;
  height: 82px;
  align-items: center;
  text-align: center;
}
.menu-group img {
  height: 40px;
}
.menu-group a {
  padding: 0rem 6rem;
}
.menu-group active {
  color: red;
}
.menubar-fix {
  position: fixed;
  width: 100%;
  bottom: 0px;
}

@media (max-width: 768px) {
  .menu-group a {
    padding: 0rem 3rem;
  }
}
