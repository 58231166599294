.input-groups {
    position: relative;
}
.input-groups label{
    margin-bottom: 0.75rem;
    display: block;
    text-align: left;
    font-size: 22px;
    font-weight: 700;
    letter-spacing: 0.025em;
    color: var(--font-dark);
    font-family: 'IBM Plex Sans', sans-serif;
}
.input-groups .text-field {
    border-radius: 5px;
    padding: 0.5rem 1rem;
    min-height: 38px;
    background-color: #EDEDED;
    border: 1px solid rgba(203, 203, 203, 0.1);
}
.input-text-group {
    justify-content: space-between;
    align-items: center;
}