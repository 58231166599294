/* Common  CSS  Start*/
.spacer-5 {
  height: 5px;
}
.spacer-10 {
  height: 10px;
}
.spacer-20 {
  height: 20px;
}
.spacer-30 {
  height: 30px;
}
.spacer-40 {
  height: 40px;
}
.spacer-50 {
  height: 50px;
}
.cursor-pointer {
  cursor: pointer;
}

/* Common  CSS  End*/

/* Layout  CSS  Start*/

.main-container {
  width: 100%;
  background-color: #060d31;
}

.outter-container {
  width: 100%;
  background-color: #fff;
}
.inner-container {
  margin: 0 auto;
  margin-top: -80px;
  padding: 0px 20px;
  max-width: 700px;
}
@media (max-width: 768px) {
  .inner-container {
    padding: 0px 14px;
  }
}
.inner-container.no-neg-mar {
  margin-top: 0px;
}
.font-color {
  color: var(--light);
  font-size: 14px;
  margin-bottom: 20px;
}
.header-sm-space {
  margin: 2rem auto;
}
@media (max-width: 700px) {
  .layout-container {
    width: 90%;
  }
}
.neg-margin {
  margin-top: -80px;
}
/* Layout  CSS  End*/

/* Footer  CSS  Start*/
.footer {
  margin-bottom: 82px;
  padding: 34px 20px 80px 20px;
  background-color: #060d31;
}
@media (max-width: 576px) {
  .footer {
    padding: 30px 30px 40px 20px;
  }
}
.footer-container {
  margin: 0 auto;
  padding: 0 20px;
  max-width: 700px;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 480px) {
  .footer-container {
    display: block;
  }
}
.footer-container img {
  max-width: 200px;
}
@media (max-width: 576px) {
  .footer-container img {
    max-width: 170px;
  }
}
.footer-top {
  display: block;
}
.footer-bottom {
  text-align: right;
}
@media (max-width: 480px) {
  .footer-bottom {
    padding-top: 15px;
    text-align: left;
  }
}
.footer a {
  color: #fff;
}
@media (max-width: 576px) {
  .footer a {
    margin: 10px 0px;
    display: block;
  }
}
/* Footer  CSS  End*/

.white-card {
  width: 100%;
  min-height: 330px;
  border: 1px solid #f0f0f0;
  background-color: #fff;
  box-shadow: 0 5px 9px rgb(0 0 0 / 16%);
  border-radius: 4px;
  border: none;
}
.white-card.height-auto {
  min-height: auto;
}
.white-card-content {
  padding: 35px 40px;
}
@media (max-width: 576px) {
  .white-card-content {
    padding: 30px;
  }
}
.white-card-content h3 {
  margin: 0px 0px 15px 0px;
  font-size: 26px;
}
.white-card-content p {
  margin: 0px 0px 15px 0px;
  font-size: 16px;
}
.white-card-content p:last-child {
  margin: 0px 0px 20px 0px;
}
/*Header*/

.header-wrap {
  background-color: #060d31;
  min-height: 190px;
}
.header-wrap h1 {
  margin: 0px;
}
.header-wrap-sm {
  padding: 40px 110px 60px 25px;
}
.header-wrap-sm.content {
  padding: 40px 40px 110px 25px;
}
@media (max-width: 768px) {
  .header-wrap h1 {
    font-size: 36px;
  }
}
.radio-relative {
  position: relative;
}
.logout-link {
  display: flex;
  justify-content: space-between;
  padding: 0px 35px;
}

.no-bg {
  background: none;
}
