@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500;600;700&family=Montserrat:wght@400;500;600;700&display=swap");
@import "./styles/styles.css";

:root {
  --light: #ffffff;
  --dark: #060d31;
  --link: #0072ce;
  --primary-btn: #007b8e;
  --secondary-btn: #007b8e;
  --primary-100: #060d31;
  --primary-200: #ffffff;
  --primary-300: #ededed;
  --primary-400: #e5317c;
  --secondary-100: #424762;
  --input: #ededed;
  --font-dark: #060c33;
}

body {
  background-color: var(--dark);
}
h1,
h2,
h3,
h4,
h5 {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 700;
}
h1 {
  font-size: 45px;
  color: var(--light);
}
h2 {
  font-size: 36px;
}
h3 {
  font-size: 32px;
  margin: 0px;
}
h4 {
  font-size: 26px;
  margin: 0px;
}
h5 {
  font-size: 22px;
  color: var(--dark);
}
a {
  font-size: 16px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 700;
  color: var(--link);
  text-decoration: none;
}
@media (max-width: 768px) {
  a {
    font-size: 14px;
  }
}
.btn {
  min-height: 34px;
  min-width: 180px;
  padding: 0 15px;
  font-family: "IBM Plex Sans", sans-serif;
  line-height: 23px;
  cursor: pointer;
  outline: none;
  border-radius: 4px;
  font-weight: 700;
  font-size: 16px;
  color: var(--light);
  box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.2);
  transition: all 300ms ease-in-out;
}
.primary-btn {
  background-color: var(--primary-btn);
}

.secondary-btn {
  background-color: var(--secondary-btn);
}

.danger-btn {
  background-color: var(--primary-400);
}

.text-btn {
  box-shadow: none;
  text-align: left;
  padding: 0;
  margin: 0;
  background: none;
  border: none;
  font-weight: bold;
}

.danger-btn-text {
  color: var(--primary-400);
}

.intro-btn {
  min-height: 70px;
  max-width: 310px;
  font-size: 18px;
}
.btn:hover {
  color: var(--light);
  box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.2);
}
.btn:focus {
  box-shadow: none;
}
p {
  font-size: 16px;
  font-weight: 400;
  font-family: "IBM Plex Sans", sans-serif;
  color: var(--font-dark);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* AccountPage --> delete span */

.delete-footer {
  color: #e5317c;
  font-weight: 700;
}

.visualizer-container {
  background-color: var(--primary-btn);
}

.max-z-index {
  z-index: 99999;
}

.app-logo-container {
  background-color: white;
  /* width: 400px;
  height: 200px; */
}

.app-logo-container img {
  height: 100%;
  width: 100%;
}

.time-percentage .progress .bg-danger {
  background-color: #e5317c !important;
}

.time-percentage .progress {
  height: 0.5rem !important;
  background-color: #060d31 !important;
}

.font-weight-bold {
  font-weight: 800;
}

.text-right {
  text-align: right !important;
}

.clear-btn {
  background: none;
  border: none;
}

.danger-btn-clear {
  color: #e5317c;
  text-decoration: underline;
  cursor: pointer;
}

.d-none {
  display: none !important;
}
