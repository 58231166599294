.nav {
  align-items: flex-end;
}
.nav-tabs .nav-link {
  min-width: 142px;
  height: 60px;
  background: #EDEDED;
  display: flex;
    align-items: center;
    justify-content: center;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  height: 70px;
  font-size: 28px;
}
.tabs-font {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 700;
  font-size: 22px;
  color: var(--dark) !important;
}
.tabs-bg-color {
  background-color: var(--light);
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}
.sm-create-form .input-groups {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.sm-create-form {
  padding: 0.1px 2rem;
}
.fp-space {
  margin-bottom: 0.5rem !important;
}
.margin-bt {
  margin: 2rem 0px !important;
}
.sign-btn {
  margin-top: 3.5rem !important;
}
.nav-link{
    padding: 0rem !important;
}
.btn-bg{
    background: transparent;
    border: none;
}
